@import '../../../../../../Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';

.singleImageComponent {
  &.padding-top {
    padding-top: 3rem;
  }

  .singleImageComponent__image {
    display: flex;
    justify-content: center;
  }

  .singleImageComponent__video-container {
    video {
      width: 100%;
    }
  }

  .singleImageComponent__text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px 50px;
    max-width: 750px;
    text-align: left;
  }

  .singleImageComponent__btn-container {
    text-align: center;
    margin-top: 1.5rem;
  }

  .singleImageComponent__overlay {
    opacity: 0.45;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    padding: 0;
  }

  .small {
    @media (min-width: @screen-lg-min) {
      margin-left: 8.33333333%;
      flex: 0 0 auto;
      width: 83.33333333%;
      display: block;

      img {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 16 / 9;
      }
    }

    @media (min-width: @screen-xl-min) {
      margin-left: 16.66666667%;
      flex: 0 0 auto;
      width: 66.66666667%;
    }
  }
}

.overlay-color {
  &--white {
    background-color: #fff;
  }

  &--black {
    background-color: @Black;
  }

  &--thule-light-grey {
    background-color: @Border;

    a {
      color: @CTAOnBackground;

      &:hover {
        text-decoration: none;
        color: darken(@CTAOnBackground, 15%);
      }
    }
  }

  &--thule-lightest-grey {
    background-color: @Background;

    a {
      color: @CTAOnBackground;

      &:hover {
        text-decoration: none;
        color: darken(@CTAOnBackground, 15%);
      }
    }
  }

  &--thule-blue {
    background-color: @CTAPrimary;
    color: white;

    a {
      color: @Black;

      &:hover {
        text-decoration: none;
        color: lighten(@Black, 15%);
      }
    }
  }
}

@media screen and (max-width: @screen-md-max) {
  .singleImageComponent {
    &.thule-container--full-width {
      .row:first-of-type {
        margin-right: 0;

        .col:first-of-type {
          padding-right: 0;
        }
      }
    }
  }
}

@media screen and (min-width: @screen-lg) {
  .singleImageComponent {
    &.padding-top {
      padding-top: 4rem;
    }
  }
}

.article-page {
  .thule-container {
    &.singleImageComponent {
      &.override {
        @media (min-width: @screen-lg-min) {
          margin-left: 8.33333333%;
          flex: 0 0 auto;
          width: 83.33333333%;
        }

        @media (min-width: @screen-xl-min) {
          margin-left: 16.66666667%;
          flex: 0 0 auto;
          width: 66.66666667%;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}